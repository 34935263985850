<template>
    <LayoutNext>
        <template #page-title>
            Daily Sync Report
        </template>
        <div class="backdrop" v-if="loadingSpinner">
            <div class="image-container d-flex justify-content-center align-items-center">
                <div class="loader"></div>
                <img src="@/assets/cs-loader.png" alt="Loading image">
            </div>
        </div>
        <MDBCard class="d-flex justify-content-center m-auto p-2 pb-1">
            <MDBCardHeader class="pb-0">
                <h5 class="fw-bold">Download Status Report</h5>
                <p>Choose a date range and a user to generate the status report. (Selecting a user is optional.)</p>
            </MDBCardHeader>
            <MDBCardBody tag="form" @submit.prevent="submit">
                <div class="d-md-flex d-block justify-content-center align-items-center mb-1 gap-3">
                    <MDBDatepicker class="mb-md-0 mb-4" v-model="dateFrom" inline label="Date From" />
                    <MDBDatepicker class="mb-md-0 mb-4 mx-1" v-model="dateTo" inline label="Date To" />
                    <MDBSelect v-model:options="options" label="Select User" filter placeholder="Select User"
                        v-model:selected="selectedOption" />
                </div>
            </MDBCardBody>
            <MDBCardFooter class="py-3 d-flex justify-content-end">
                <MDBBtn class="fw-bold" type="submit" color="primary" size="sm" @click="submit">Download Report</MDBBtn>
            </MDBCardFooter>
        </MDBCard>
        <MDBToast v-model="toastObject.state" :delay="2000" autohide position="top-right" appendToBody stacking
            width="350px" :color="toastObject.color" text="white" :icon="toastObject.icon">
            <template #title>
                {{ toastObject.title }}
            </template>
            {{ toastObject.message }}
        </MDBToast>
    </LayoutNext>
</template>

<script setup>
import LayoutNext from "@/views/v3/LayoutNext.vue";
import { MDBCard, MDBCardBody, MDBCardHeader, MDBCardFooter, MDBSelect, MDBDatepicker, MDBBtn, MDBToast } from "mdb-vue-ui-kit";
import { GetVideoUpdatesReport } from '@/services/Notifications/VideoUpdatesReport'
import { ref, onMounted } from "vue";
import { GetCreatorsList } from '@/services/CreatorVideoUpdates/GetCreatorsList'
import { parseDateDash } from "@/helpers/parseDate";
import { useTitle } from "@vueuse/core";

useTitle("Daily Sync Report | Creator Shield");

const options = ref([{ text: "Loading", value: '' }]);
const dateFrom = ref('');
const dateTo = ref('');
const selectedOption = ref('')

const loadingSpinner = ref(false);

const toastObject = ref({
    state: false,
    message: "",
    color: "",
    icon: "",
});

const submit = async () => {
    let selectedId = 0;
    let selectedName = '';

    if (selectedOption.value) {
        const selectedArr = selectedOption.value.split("-");
        selectedId = selectedArr[0];
        selectedName = selectedArr[1];
    }

    if (dateFrom.value > dateTo.value) {
        toastObject.value.state = true;
        toastObject.value.title = "Error!";
        toastObject.value.message = "'Date From' must not be greater than 'Date To'";
        toastObject.value.color = "danger";
        toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
        return;
    }

    if (dateFrom.value === '' || dateTo.value === '') {
        toastObject.value.state = true;
        toastObject.value.title = "Error!";
        toastObject.value.message = "Dates must not be empty.";
        toastObject.value.color = "danger";
        toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
        return;
    }

    const params = {
        DateFrom: parseDateDash(dateFrom.value),
        DateTo: parseDateDash(dateTo.value),
        ...(selectedId !== 0 ? { CreatorId: selectedId } : {})
    };

    try {
        loadingSpinner.value = true;

        const response = await GetVideoUpdatesReport(params);

        let fileURL = window.URL.createObjectURL(
            new Blob([response], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
        );
        let fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute(
            "download",
            `CreatorShield-VideoUpdatesStatusReport ${selectedId !== 0 ? '(' + selectedName + ')' : ''} - ${parseDateDash(dateFrom.value)} - ${parseDateDash(dateTo.value)}.xlsx`
        );
        document.body.appendChild(fileLink);
        fileLink.click();
        toastObject.value.state = true;
        toastObject.value.title = "Success!";
        toastObject.value.message = "Downloaded Successfully!";
        toastObject.value.color = "success";
        toastObject.value.icon = "fas fa-check fa-lg me-2";
    } catch (error) {
        toastObject.value.state = true;
        toastObject.value.title = "Error!";
        toastObject.value.message = "An error occurred while generating the report.";
        toastObject.value.color = "danger";
        toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
    } finally {
        loadingSpinner.value = false;
    }
};

onMounted(async () => {
    const response = await GetCreatorsList();
    options.value = response.map((creator) => {
        return {
            ...creator,
            text: `${creator.creatorName}`,
            value: `${creator.id}-${creator.creatorName}`,
        }
    });
    options.value.unshift({
        text: 'All',
        value: 0
    });
});
</script>

<style scoped>
.btn-primary {
    background-color: var(--primary);
}

.btn-outline-primary {
    border-color: var(--accent);
    color: var(--accent);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

.image-container {
    position: relative;
    display: inline-block;
}

.image-container .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 50px;
    height: 50px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top-color: rgb(153, 153, 153);
    border-radius: 50%;
    animation: BorderSpin 1s linear infinite;
}

.image-container img {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

@keyframes BorderSpin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
